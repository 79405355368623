import React from 'react';
import BreadcrumbSchema from '../../components/BreadcrumbSchema';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { FaRedo } from 'react-icons/fa';
import ServiceTemplate from '../../components/service/ServiceTemplate';

const WebNew = ({ data }) => {
  const seoData = {
    title: 'ホームページリニューアル｜奈良のホームページ制作なら｜インヴォルブ',
    description:
      'あなたのホームページを最新のトレンドに合わせて進化させます。デザインや機能を見直し、時代に即したスタイリッシュで使いやすいウェブサイトへ。魅力的なビジュアルと最適なユーザー体験を実現し、ビジネスの価値を最大限に引き出します。',
    pageUrl: 'https://www.involve-in.jp/service/renewal',
    ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
  };

  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    { name: 'サービス一覧', url: 'https://www.involve-in.jp/service/' },
    { name: 'ホームページリニューアル', url: seoData.pageUrl },
  ];

  const works = data.allContentfulCaseStudy.nodes; // Contentful から施工事例を取得

  return (
    <>
      {/* ✅ 構造化パンくずデータを渡す */}
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <ServiceTemplate
        title="ホームページリニューアル"
        subtitle="サイト改善・最適化"
        icon={FaRedo}
        description="あなたのホームページを最新のトレンドに合わせて進化させます。デザインや機能を見直し、時代に即したスタイリッシュで使いやすいウェブサイトへ。魅力的なビジュアルと最適なユーザー体験を実現し、ビジネスの価値を最大限に引き出します。"
        seo={{
          title:
            'ホームページリニューアル｜奈良のホームページ制作なら｜インヴォルブ',
          description:
            'あなたのホームページを最新のトレンドに合わせて進化させます。デザインや機能を見直し、時代に即したスタイリッシュで使いやすいウェブサイトへ。魅力的なビジュアルと最適なユーザー体験を実現し、ビジネスの価値を最大限に引き出します。',
          pageUrl: 'https://www.involve-in.jp/service/renewal',
          ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
        }}
        breadcrumbs={[
          { name: 'ホーム', url: 'https://www.involve-in.jp/' },
          {
            name: 'サービス一覧',
            url: 'https://www.involve-in.jp/service/',
          },
          {
            name: 'ホームページリニューアル',
            url: 'https://www.involve-in.jp/service/renewal',
          },
        ]}
        benefits={[
          {
            title: 'デザインの改善',
            description:
              '時代遅れのデザインを一新し、最新のトレンドを取り入れた魅力的なサイトへ。洗練されたビジュアルで、第一印象をより強く引きつけます。',
          },
          {
            title: '機能性向上',
            description:
              'スマートフォン最適化や表示速度の改善など、ユーザビリティを向上。誰にとっても快適に使える、ストレスのないサイトを実現します。',
          },
          {
            title: '正しいサイト構造へ',
            description:
              '適切な記述と最適化された構造で、検索エンジンにも強いサイトへ改善。集客力を高め、成果につながるホームページを構築します。',
          },
        ]}
        flow={[
          {
            title: '現状分析',
            duration: '1週間',
            description:
              '現在のホームページのデザイン・機能・SEOの状態を徹底分析し、課題や改善点を明確にします。',
          },
          {
            title: '改善提案',
            duration: '1週間',
            description:
              '分析結果をもとに、デザインや機能のリニューアルプランをご提案。目的に合った最適な改善策を提示します。',
          },
          {
            title: 'デザインリニューアル',
            duration: '2週間',
            description:
              '最新のトレンドを取り入れた新しいデザインを作成し、サイト全体をより魅力的で洗練されたビジュアルへとアップデートします。',
          },
          {
            title: 'システム・SEO調整',
            duration: '2週間',
            description:
              '内部構造の最適化やSEO設定の調整を行い、検索エンジンにも強い効果的なホームページへと仕上げます。',
          },
          {
            title: '公開・運用開始',
            duration: null,
            description:
              '最終調整を経て、リニューアルサイトを公開。必要に応じて運用サポートも提供し、サイトの成長を継続的に支援します。',
          },
        ]}
        preparation={[
          {
            title: '現状サイトの確認',
            description:
              '現在のホームページのデザインやコンテンツのどこを改善したいかを洗い出しましょう。',
          },
          {
            title: 'リニューアルの目的',
            description:
              '「集客を強化したい」「ブランドイメージを刷新したい」など、リニューアルの目的を明確にしましょう。',
          },
          {
            title: '写真やコンテンツの整理',
            description:
              '掲載する写真や文章を整理し、新しい素材を準備しておくとスムーズに進みます。',
          },
          {
            title: '予算・納期の確認',
            description:
              'ご希望の予算や納期を事前に相談いただくことで、最適なリニューアル計画を立てることができます。',
          },
        ]}
        works={works.map((work) => ({
          title: work.title,
          image:
            work.image && work.image.length > 0
              ? getImage(work.image[0].gatsbyImageData)
              : null, // 🔥 最初の画像のみ取得
        }))}
        price="¥200,000〜"
        cta="まずは無料相談"
      />
      {/* ✅ 表示用のパンくずは ServiceTemplate で管理 */}
    </>
  );
};

// GraphQL クエリ（Contentful の施工事例を取得）
export const query = graphql`
  query {
    allContentfulCaseStudy(sort: { publishDate: DESC }) {
      nodes {
        title
        slug
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            formats: [AUTO, WEBP]
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;

export default WebNew;
